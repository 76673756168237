import React from "react"
import {Link, graphql} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Home"/>
    <section>
      <h4>{data.allMarkdownRemark.totalCount} Posts - <Link to="/tags">Tags</Link> - <Link to="/about">About</Link></h4>
      {data.allMarkdownRemark.edges.map(({node}) => (
        <article key={node.id}>
          <Link to={node.fields.slug}>
            <h3> {node.frontmatter.title}{" "}<span>— {node.frontmatter.date}</span></h3>
          </Link>
          <p>{node.excerpt}</p>
        </article>
      ))}
    </section>
  </Layout>
);

export const data = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;

export default IndexPage
